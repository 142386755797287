/* App.css */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background: linear-gradient(135deg, #000000, #7478b3, #903749); /* Vibrant gradient background */
  background-size: 300% 300%;
  animation: gradientBG 5s ease infinite;
  color: white; /* White text for contrast */
  margin: 0; /* Add spacing to prevent content from being overlapped */
  padding-top: 4rem; /* Adjust based on navbar height */
}

/* Background animation */
@keyframes gradientBG {
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.235); /* Semi-transparent white background */
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
}

.content {
  max-width: 400px;
  width: 100%;
}

.logo {
  max-width: 320px;
  border-radius: 50%;
  margin-bottom: 20px;
  border: 0px solid #ff3f96; /* Vibrant border around logo */
}

h1 {
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 10px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
}

.description {
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 20px;
}

.x-link {
  display: inline-block;
  margin-top: 20px;
  margin-right: 10px;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.x-logo {
  width: 30px;
  height: 30px;
  transition: transform 0.3s ease;
}

/* Hover effects */
.x-link:hover .x-logo {
  transform: scale(1.1);
}

.x-link:hover {
  transform: scale(1.1);
}

.logo:hover {
  transform: scale(1.07);
}

/* IP LOCATION STYLE */
.clientInfo {
  margin-top: 20px; /* Space from the container above */
  color: #fff; /* White text for contrast */
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5); /* Subtle shadow for readability */
  font-size: 1rem; /* Standard font size */
  text-align: center; /* Center the text */
  z-index: 10; /* Ensure it's above other elements */
}

.clientInfo p {
  margin: 5px 0; /* Small space between lines */
}

.clientInfo p:first-of-type {
  font-weight: bold; /* Highlight the IP Address */
  color: #ff3f96; /* Vibrant pink for emphasis */
}

.clientInfo p:last-of-type {
  color: #ddd; /* Slightly muted white for location info */
}



/* FOOTER STYLE */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 1rem;
}

.footer span {
  color: red; /* Color the heart emoji red */
}

/* ARTICLES SECTION */
.articles {
  margin: 40px 20px;
  text-align: left;
}

.articles h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
}

.article {
  background-color: rgba(255, 255, 255, 0.235);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.article h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.article p {
  font-size: 1rem;
  color: #ddd;
}

.article .read-more {
  display: inline-block;
  margin-top: 10px;
  color: #ff3f96;
  text-decoration: none;
  font-weight: bold;
}

.article:hover {
  transform: scale(1.02);
}


/* For privacy and terms */
/* General Styling */
.page-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Montserrat, serif;
  line-height: 1.6;
  background-color: rgba(255, 255, 255, 0.235); /* Semi-transparent white background */
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
}

.page-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: white;
  text-align: center;
  font-weight: bold;
}

.page-content {
  font-size: 1rem;
  color: white;
}

.page-content ul {
  margin: 1rem 0;
  padding-left: 1.5rem;
}

.page-content ul li {
  margin-bottom: 0.5rem;
}

.page-footer {
  text-align: center;
  margin-top: 2rem;
  font-size: 0.9rem;
  color: white;
}

.footer-link {
  color: #0066cc;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
  color: #ff3f96;
}



/* Navbar */
/* Navbar Styles */
/* Navbar Styles */
.navbar {
  position: fixed; /* Ensures the navbar stays fixed at the top */
  top: 0; /* Aligns it to the top */
  left: 0; /* Aligns it to the left */
  width: 100%; /* Spans the full width of the screen */
  background-color: #333; /* Background color */
  color: #fff; /* Text color */
  padding: 1rem 2rem; /* Padding for spacing */
  z-index: 1000; /* Ensures it stays above all other elements */
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Navbar container for links and logo */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  margin: 0;
  padding: 0;
}

.navbar-link {
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  transition: color 0.3s;
}

.navbar-link:hover {
  color: #ff3f96;
}


/* ARTICLE PAGE */
.articles-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.articles-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.article-card {
  background-color: rgba(255, 255, 255, 0.235);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.article-card h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.article-card p {
  font-size: 1rem;
  color: whitesmoke;
}

.article-card .read-more {
  font-size: 0.9rem;
  color: #ff3f96;
  text-decoration: none;
  transition: color 0.3s;
}

.article-card .read-more:hover {
  color: #b90456;
}

/* ArticleDetail Page Styling */
.article-detail {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

.article-detail h1 {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: white;
}

.article-detail p {
  font-size: 2rem;
  color: white;
  margin-bottom: 10px;
}

.article-detail a {
  display: inline-block;
  margin-top: 20px;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.article-detail a:hover {
  text-decoration: underline;
}
